import React from 'react'
// import Particles from 'react-particles-js'

const ParticlesBackground = () => {
    return (
        <div className="particle">
            <div style={{height: "100vh", width: "100%"}}>

            </div>
            {/* <Particles
            height="100vh"
            width="100%"
                params={{
                    "particles": {
                        "number": {
                            "value": 20,
                            "density": {
                                "enable": true,
                                "value_area": 800
                            }
                        },
                        "line_linked": {
                            "enable": false
                        },
                        "move": {
                            "speed": 1,
                            "out_mode": "out"
                        },
                        "shape": {
                            "type": [
                                "image",
                                "circle"
                            ],
                            "image": [
                                {
                                    "src": "/scrollbar.png",
                                    "height": 20,
                                    "width": 20
                                },
                                {
                                    "src": "/beer.png",
                                    "height": 30,
                                    "width": 22
                                },
                                {
                                    "src": "/cocktail.png",
                                    "height": 20,
                                    "width": 20
                                },
                            ]
                        },
                        "color": {
                            "value": "#CCC"
                        },
                        "size": {
                            "value": 30,
                            "random": false,
                            "anim": {
                                "enable": true,
                                "speed": 4,
                                "size_min": 10,
                                "sync": false
                            }
                        }
                    },
                    "retina_detect": false
            }} /> */}
           
        </div>
    )
}

export default ParticlesBackground
