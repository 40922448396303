import React from "react";
import HeaderButton from "../layouts/HeaderButton";
import { header } from "../../profile";

const Header = () => {
  // const scrollTo = () => {
  //     window.scrollTo({
  //         top: 100000,
  //         left: 0,
  //         behavior: "smooth"
  //     })
  // }

  const toggleDarkMode = (e) => {
    document.documentElement.classList.toggle("dark-mode");
    document.getElementById("not-dark2").classList.toggle("inverse-dark");
    document.getElementById("not-dark").classList.toggle("inverse-dark");
    var x = document.getElementsByClassName("img-pro");
    for (let i = 0; i < x.length; i += 1) {
      x.item(i).classList.toggle("inverse-dark");
    }

    if (document.documentElement.classList.contains("dark-mode"))
      localStorage.setItem("mode", "Dark");
    else {
      localStorage.setItem("mode", "Light");

      setTimeout(() => {
        alert(
          "Technically there is light theme, but it is too ugly so please don't use it. Thanks"
        );
        toggleDarkMode();
      }, 1000);
    }
  };

  return (
    <div>
      <div className="Header">
        <h1>{`Hello, I'm ${header.name}`}</h1>
        <p className="line-1 anim-typewriter">
          and this is my scrollbar application...
        </p>
        <label className="switch">
          <input
            id="mode-switch"
            onClick={(e) => toggleDarkMode(e)}
            type="checkbox"
          />
          <span className="slider round"></span>
        </label>
        <div class="container">
          <div class="chevron"></div>
          <div class="chevron"></div>
          <div class="chevron"></div>
          <span class="text">Scroll down</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
