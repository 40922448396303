import React from 'react'
import { contact, section5Title, social } from '../../profile'

const Contact = () => {
    
    return (
        <div className="parallax" id="not-dark">
                
        </div>
    )
    
}

export default Contact
