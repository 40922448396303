import React from "react";
import ScrollTrigger from "react-scroll-trigger";

const Wheel = () => {
  const ref = React.useRef(null);

  const onEnterViewport = () => {
    console.log(ref.current.contentWindow.postMessage({}, "*"));
  };

  return (
    <div id="wheel" className="effect2" style={{textAlign: 'center'}}>
      <ScrollTrigger onEnter={onEnterViewport}>
        <div >
          <h2>{`First up, I think that we should ask the genie...`}</h2>
          <h1>{`Should you accept my scrollbar application?`}</h1>
        </div>
        <div
          data-aos="zoom-in-up"
          data-aos-once="true"
          className="row"
          style={{ height: 500 }}
        >
          <iframe
            title="wheel"
            src="https://felixdq.github.io/scrollbar/"
            frameBorder="0"
            ref={ref}
          />
        </div>
        <p>... Made with the same breakthrough AI block chain technology as a certain <a href="https://codepen.io/felixdq/full/vYmypao" target="_blank">upcomming wheel of fortune</a></p>
      </ScrollTrigger>
    </div>
  );
};

export default Wheel;
