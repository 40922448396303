import React from "react";
import Skills from "../layouts/Skills";
import { about, skillsBar, section2title } from "../../profile";
import { Statistic } from "antd";
import { List, Typography, Divider } from "antd";

const AgeComponent = () => {
  const [age, setAge] = React.useState(new Date() - new Date("1998-02-22"));

  React.useEffect(() => {
    const interval = setInterval(
      () => setAge(new Date() - new Date("1998-02-22")),
      100
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <p>
      <strong>Age:</strong>{" "}
      {Math.round(age / ((60 * 60 * 24 * 365) / 10000000)) / 10000000000} years
    </p>
  );
};

const About = () => {
  return (
    <div id="about" className="effect2">
      <div data-aos="zoom-in-up" data-aos-once="true" className="row" style={{marginBottom: 16}}>
        <div
          className="col-12 d-none d-md-block offset-md-1 col-md-4 Photo"
          id="not-dark2"
        ></div>
        <div className="col-12 offset-md-1 col-md-6 about">
          <div className="About-title-box">
            <h1 id="About" className="red-line">
              {section2title}
            </h1>
          </div>
          <p className="lead about-text">{about.paragraph}</p>
        </div>
      </div>
      <div data-aos="zoom-in-up" data-aos-once="true" className="row">
        <div className="col-12 offset-md-1 col-md-6 about">
          <div className="About-title-box">
            <h1 id="About2" className="red-line">
            Some stats about me
            </h1>
          </div>
          <AgeComponent />
          <p>
            <strong>Can I wiggle my ears:</strong> Yes
          </p>
          <p>
            <strong>Good at web design:</strong> No
          </p>
          <p>
            <strong>Favorite cocktail:</strong> Dark 'N' Stormy
          </p>

          <p>
            <strong>Full name:</strong> Felix Dawids Qvist
          </p>
          <p>
            <strong>ITU email:</strong> <a href="mailto:feqv@itu.dk">feqv@itu.dk</a>
          </p>
          <p className="d-block d-md-none">
            <strong>Recent picture:</strong> <a href="https://qvist.dev/portrait.png">link</a> <emph>(... or visit the site on a desktop)</emph>
          </p>
        </div>
        <div className="col-12 d-none d-md-block offset-md-1 col-md-4"></div>
      </div>

      <div data-aos="zoom-in-up" data-aos-once="true" className="row">
        <div className="col-12 d-none d-md-block offset-md-1 col-md-4"></div>
        <div className="col-12 offset-md-1 col-md-6 about">
          <div className="About-title-box">
            <h1 id="About2" className="red-line">
              Why I'm applying
            </h1>
          </div>
          <p className="lead about-text">Scrollbar is a really fun place to be and a place that I've missed a lot during the lockdown. I would love to help out with the reopening and help ensure that the students who haven't experienced Scrollbar before will get the same awesome experiences as the ones before corona. </p>
        </div>
      </div>
    </div>
  );
};

export default About;
